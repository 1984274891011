<template>
  <main class="form-container">
    <p class="-xltb title">Create New Project</p>
    <div class="column-span-4 flex-column">
      <p class="-ntb">Project Info</p>
      <p class="-xstr" style="color: var(--gray3)">Input details here</p>
      <hr style="margin-top: 20px" />
    </div>
    <div class="column-span-4">
      <p class="-xstb column-span-1">
        Project Reference <span title="Required field">*</span>
      </p>

      <div class="column-span-2">
        <input
          type="text"
          v-model.trim="reference"
          ref="referenceRef"
          class="-xstb"
          @focus="validateString(reference, 'referenceRef')"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
      <hr class="column-span-4" />
    </div>

    <div class="column-span-4">
      <p class="-xstb column-span-1">
        Project Title <span title="Required field">*</span>
      </p>
      <div class="column-span-2">
        <input
          type="text"
          v-model.trim="title"
          ref="titleRef"
          class="-xstb"
          @focus="validateString(title, 'titleRef')"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>
    <div class="column-span-4">
      <p class="-xstb column-span-1">
        Framework <span title="Required field">*</span>
      </p>
      <div class="column-span-2">
        <select
          ref="frameworkRef"
          class="-xstb"
          @change="changeDpdownValue('frameworkRef')"
          @focus="validateString(framework, 'frameworkRef')"
        >
          <option selected disabled hidden>{{ frameworksList[0] }}</option>
          <option v-for="item in frameworksList" :key="item" class="-xstb">
            {{ item }}
          </option>
        </select>
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>
    <div class="column-span-4" style="grid-row: span 2">
      <div class="column-span-1">
        <p class="-xstb">Country <span title="Required field">*</span></p>
        <p class="-xstr" style="color: var(--gray3)">
          Select your project's location
        </p>
      </div>
      <div class="column-span-2 flex gap8">
        <img
          :src="selectedCountry.flag"
          :alt="selectedCountry.name"
          class="flag"
          v-if="selectedCountry"
        />
        <div class="flex-column width100">
          <select
            @change="
              [
                setSelectedCountry('countryRef'),
                validateString(selectedCountry, 'countryRef'),
              ]
            "
            ref="countryRef"
            class="-xstr"
          >
            <option selected disabled hidden>Select a country</option>
            <option v-for="country in countriesList" :key="country">
              {{ country.name }}
            </option>
          </select>
          <p class="-xstb error-msg">This field is required!</p>
        </div>
      </div>

      <hr class="column-span-4" style="margin-top: 20px" />
    </div>
    <div class="column-span-4" style="grid-row: span 2">
      <div class="column-span-1">
        <p class="-xstb">Description</p>
        <p class="-xstr" style="color: var(--gray3)">
          Write a short description
        </p>
      </div>
      <div class="column-span-2 flex-column">
        <textarea
          rows="4"
          cols="50"
          maxlength="255"
          v-model.trim="description"
          ref="descRef"
          class="column-span-2 -xstb"
        ></textarea>
        <p class="-xstr" style="color: var(--gray3)">
          {{ remainingChars }} characters left
        </p>
      </div>

      <hr class="column-span-4" style="margin-top: 20px" />
    </div>

    <section class="form-btns-group">
      <BaseCancelSave
        @closeModal="$emit('closeModal')"
        @handleClick="handleClick(['referenceRef', 'titleRef', 'countryRef'])"
      />
    </section>
  </main>
</template>

<script>
import { validateForm } from "../../../helpers/formValidations";
import { removeErrInputText } from "../../../helpers/styleToggler";
import BaseCancelSave from "../../utils/BaseCancelSave.vue";
import { createProject } from "../../../services/requests/post/projects";
import { getCountries } from "../../../services/requests/get/getCountries";
export default {
  components: {
    BaseCancelSave,
  },
  data() {
    return {
      reference: "",
      title: "",
      framework: "",
      description: "",
      countriesList: null,
      selectedCountry: "",
      /**/
      frameworksList: ["RIBA Plan of Work"],
      frame_id: 1,
    };
  },
  async created() {
    this.countriesList = await getCountries();
  },

  methods: {
    changeDpdownValue(ref) {
      this.framework = this.$refs[ref].value.trim();
    },
    async handleClick(refsArray) {
      //prepare info for validateForm()
      const infoToValidate = [
        this.reference,
        this.title,
        // this.framework, when we have more frameworks on the db we can add this and also on the function call add the ref
        this.getCountryName(),
      ];
      let arrayOfElements = [];
      refsArray.forEach((element) => {
        arrayOfElements.push(this.$refs[element]);
      });

      if (!this.validateForm(arrayOfElements, infoToValidate)) return;

      console.log("validated, send request"); //write api code here

      const data = {
        frame_id: this.frame_id,
        project_name: this.title,
        reference: this.reference,
        descrip: this.description,
        proj_country: this.selectedCountry.name,
      };
      const res = await createProject(data);

      if (res != 200) return;

      this.$router.go();
    },
    validateForm(arrayOfElements, infoToValidate) {
      const result = validateForm(arrayOfElements, infoToValidate);
      return result;
    },
    validateString(value, inputRef) {
      if (!value.length) removeErrInputText(this.$refs[inputRef]);
    },
    setSelectedCountry(ref) {
      const dropdownVal = this.$refs[ref].value.trim();

      this.selectedCountry = this.countriesList.find(
        (country) => country.name === dropdownVal
      );
    },
    getCountryName() {
      return this.selectedCountry.name ? this.selectedCountry.name : "";
    },
  },
  computed: {
    remainingChars() {
      let maxChars = 255;
      return maxChars - this.description.length;
    },
  },
};
</script>

<style scoped>
.form-container {
  height: 100%;
  width: 100%;
  padding-block: 16px;
  padding-inline: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(9, 1fr); */
  gap: 4px;
  overflow: auto;
}
.title {
  grid-column: span 4;
}
.column-span-4 {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.column-span-3 {
  grid-column: span 3;
}
.column-span-2 {
  grid-column: span 2;
}
.column-span-1 {
  grid-column: span 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

span {
  color: var(--error);
}
input,
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
select {
  height: 38px;
}
input,
textarea {
  width: 100%;
}
select {
  width: 100%;
}
textarea {
  resize: none;
  height: 110px;
}

hr {
  border: none;
  border-top: 1px solid var(--gray6);
  margin-block: 8px;
}
.flag {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  outline: 1px solid var(--gray6);
}
/**EFFECTS **/
input:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
p {
  user-select: none;
}
@media screen and (max-width: 719px) {
  .column-span-3,
  .column-span-2,
  .column-span-1 {
    grid-column: span 4;
  }
  select {
    width: 100%;
  }
  hr {
    border: none;
  }
}
</style>